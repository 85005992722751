@import "mixins"
.sliderContainer
  & :global
    .slick-track
      display: flex
      min-width: 0
    .slick-slide
      width: 100%

    .slick-active
      :local(.topWinnersList)
        > div
          transform: rotateX(0deg)
          opacity: 1
          z-index: 1

.topWinnersList
  width: 100%
  display: flex
  flex-direction: column

  > div
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275)
    transition-duration: 0.8s
    transition-property: transform, opacity
    transform: rotateX(180deg)
    opacity: 0

    &:nth-child(1)
      transition-delay: 300ms

    &:nth-child(2)
      transition-delay: 500ms

    &:nth-child(3)
      transition-delay: 700ms

    &:nth-child(4)
      transition-delay: 900ms

    &:nth-child(5)
      transition-delay: 1.2s

.topWinner
  width: 100%
  padding-right: 10px
  min-height: 38px
  @include flex
  background: var(--casinoCompact_topWinnerBg)
  border-radius: 3px
  position: relative

  &:not(:nth-child(5))
    margin-bottom: 3px

  &:nth-child(4),
  &:nth-child(5)
    background: var(--casinoCompact_topWinnerBg2)

  .topWinnerImgCasino
    min-width: 34px
    height: 34px
    margin: 2px 10px 2px 2px
    border: 1px solid #000
    border-radius: 3px
    overflow: hidden
    position: relative
    img
      position: absolute
      top: 50%
      transform: translateY(-50%)

  .topWinnerImg
    min-width: 22px
    height: 38px
    padding: 0 14px
    @include flex

  > div
    flex: 1
    padding: 3px 0
    max-width: calc(100% - 32px)

    > div
      @include bold
      font-size: 14px
      line-height: 14px
      @include flex
      justify-content: space-between

      :global(.base-icon-currency-xrp):before
        font-size: 10px
        margin-top: -1px

      :global(.base-icon-currency-trx):before
        font-size: 13px
        margin-top: -2px

    > span
      color: rgb(var(--primaryColor))
      display: block
      margin-top: 4px
      font-size: 13px
      @include ellipsis
      line-height: 14px
      max-width: 100%

.headerTitle > strong
  @include fadeIn(.8s)

.crown
  @include flex
  span
    @include flex
  svg
    width: 16px
