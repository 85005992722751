@import "mixins"
.header
  border-bottom: 1px solid var(--header1_borderBottom)
  height: var(--header1_height)

  --verificationReminderLeft: -18px
  --verificationReminderBottom: calc((16px - var(--header_userIconSize)) / 2)

  + *
    --header_height: var(--header1_height)

  ::-webkit-scrollbar
    width: 8px

  ::-webkit-scrollbar-thumb
    background: rgba(#3e4550, 0.8)
    border: 2px solid #2c3138
    border-radius: 10px

  a:global(.header-menu-item-active)
    box-shadow: inset 0 -2px rgb(var(--thirdaryColor))
    color: var(--header1_navItemActiveColor)

  & :global
    .user-panel-links
      margin-right: 6px
    .site-language-container
      @include flex
      > span:first-of-type
        margin: -1px 14px 0

    @media only screen and (min-width: 1230px)
      .site-language-container
        position: absolute
        right: 12px

.headerTop
  background: var(--header1_topBg)
  height: 44px
  padding: 0 8px 0 44px
  @include flex

  > a:first-of-type
    min-width: var(--header_logoWidth)
    :global(.imageNext)
      display: inline-flex
      vertical-align: top

  h1
    font-weight: normal
    margin-right: auto
    margin-left: 12px
    @include ellipsis
    line-height: 22px
    flex: 1
    padding-right: 20px

.headerNav
  @include flex
  padding: 0 12px
  background: var(--header1_navBg)
  height: 31px
  clear: both
  position: relative
  a:hover
    color: var(--header1_navItemActiveColor)
